import { WlcmOption } from '@wlcm/angular/core';

export enum SubmissionBreak {
  NO_BREAK = 'No break',
  MIN_30 = '30 mins (12:00-12:30)',
  HOUR_1 = '1 hour',
  HOUR_2_3 = '2-3 hours',
  HOUR_4 = '4 hours',
}

export const SUBMISSION_BREAKS: WlcmOption[] = [
  { value: SubmissionBreak.NO_BREAK, viewValue: SubmissionBreak.NO_BREAK },
  { value: SubmissionBreak.MIN_30, viewValue: SubmissionBreak.MIN_30 },
  { value: SubmissionBreak.HOUR_1, viewValue: SubmissionBreak.HOUR_1 },
  { value: SubmissionBreak.HOUR_2_3, viewValue: SubmissionBreak.HOUR_2_3 },
  { value: SubmissionBreak.HOUR_4, viewValue: SubmissionBreak.HOUR_4 },
];
